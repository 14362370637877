export function defaultInstruments() {
  return [{ supportedMethods: ["basic-card"] }];
}

export function defaultDetails() {
  return {} as PaymentDetailsInit;
}

export function defaultOptions() {
  return {};
}
